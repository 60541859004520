import Vue from 'vue'
import {mapActions, mapMutations, mapGetters} from 'vuex';
import { required, email } from 'vuelidate/lib/validators'
import localTest from '@/components/HelloI18n.vue'


export default Vue.extend( {
    components: {localTest},
    data(){
        return{
            form:{
                email:"",
                password:"",
                "remember_me":false
            },
            errorResponse: false,
        }
    },
    validations: {
        form:{
            email:{
                required,
                email
            },
            password: {
                required
            }
        }
    },
    computed:{
      ...mapGetters({
          auth:'auth/auth'
      })
    },
    methods:{
        SignIn(){
            this.toggleLoadingStatus(true);
            this.postSignIn(this.form).then(e=>{
                this.toggleLoadingStatus(false);
                if (e.status) {
                  this.soundMusicPlay("LogON");
                  this.$router.go();
                } else {
                  this.errorResponse = true;
                  let key;
                  for (key in e.data.errors) {
                    this.$toasted.error(e.data.errors[key], {
                      duration: 3000,
                    });
                  }
                }
            });
        },
        ...mapActions({
            postSignIn:'auth/postSignIn'
        }),
        ...mapMutations({
            toggleLoadingStatus: 'config/toggleLoadingStatus',
            soundMusicPlay: 'config/soundMusicPlay',
        }),

    }

})

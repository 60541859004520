import {mapGetters, mapMutations, mapActions} from 'vuex';
const _ = require('lodash');

export default {
    props: ['popupType', 'selectedItem','status'],
    data() {
        return {
            toggleTextarea:false,
            productCategoryList:[],
            form: {
                title: '',
                id:0,
                full_title: '',
                category_id: '',
                model_id: '',
                department_id: '',
                material: '',
                manufacturer_id: '',
                status: 1,
                images: [{
                    image:'',
                }],
                sku: '',
                isbn: '', 
                default_width:0,
                default_height:0,
                default_length:0,
                default_weight:0,
                description:'',
                upsell_manager_motivation: 0,
            },
            // hasImage: false,
            imageFile: null,
            imageSrc: null,
            imageUrl: ''
        }
    },
    computed: {
        ...mapGetters({
            product: 'catalog/product',
            productCategories: 'catalog/productCategories',
            manufacturers: 'catalog/manufacturers',
            departments: 'catalog/departments',
            baseUrl: 'config/baseUrl',
            models: 'catalog/models',
        }),
    },
    watch: {
        product(e) {
            if (e) {
                this.imageSrc = e.images[0].image;

                this.form = {
                    title: e.title ? e.title : '',
                    id: e.id ? e.id : 0,
                    status: Boolean(e.status),
                    full_title: e.full_title ? e.full_title : '',
                    category_id: e.category_id ? e.category_id : null,
                    model_id: e.model_id ? e.model_id : null,
                    department_id: e.department_id ? e.department_id : null,
                    manufacturer_id: e.manufacturer_id ? e.manufacturer_id : null,
                    description: e.description ? e.description : null,
                    sku: e.sku ? e.sku : '',
                    isbn: e.isbn ? e.isbn : '',
                    images: e.images ? e.images : '',
                    sale_price: e.sale_price ? Number(e.sale_price).toFixed(2) : 0,
                    current_buy_price: e.current_buy_price ?  Number(e.current_buy_price).toFixed(2) : Number(e.current_buy_price_in_usd).toFixed(2),
                    discount_price: Number(e.discount_price).toFixed(2),
                    upsell_manager_motivation: e.upsell_manager_motivation ? Number(e.upsell_manager_motivation).toFixed(0) : 0,
                    default_width: e.default_width ? e.default_width : e.category.default_width,
                    default_height: e.default_height ? e.default_height : e.category.default_height,
                    default_length: e.default_length ? e.default_length : e.category.default_length,
                    default_weight: e.default_weight ? e.default_weight : e.category.default_weight,
                    minimum: e.minimum ? e.minimum : 0,
                }
            }
        },
        productCategories(e){
            this.productCategoryList = _.clone(e.data);
            this.productCategoryList.map(item=>{
                if(item.children.length){
                    item.children.map(childMap=>{
                        this.productCategoryList.push(childMap);
                    })
                }
            })
        },
    },
    created() {
        this.getProductCategories();
        this.getManufacturers();
        this.getDepartments();
        this.getModels();
        this.form.isbn = this.$moment().format("DD MM YYYY HH mm ss").split(' ').join('').slice(1, 14);
    },
    methods: {
        changeFile(e) {
            if (!e.target.files[0]) {
                return;
            }
            const file = e.target.files[0];
            this.imageFile = file;
        
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.imageSrc = reader.result.toString();
                this.form.images = [{ image: this.imageSrc }];  // Оновлюємо images
            };
            reader.onerror = error => console.error("Error loading image: ", error);
        },
        removeImage() {
            this.imageSrc = null
            this.form.images = [{ image: "" }];
            this.imageFile = null
        },
        closePopup() {
            this.$emit('closePopup');
            this.changeShowProductPopup(false)
        },
        sendModel() {
            this.form.current_buy_price = Number(this.form.current_buy_price);
            this.form.status = Number(this.form.status);
            if(this.imageFile){
                this.storeProductImage(this.imageFile).then( res => {
                    if(res.status){
                        this.form.images[0].image = res.data.image;
                        if (this.product) {
                            const payload = {
                                id: this.product.id,
                                data: this.form
                            }
                            this.$emit('changeProduct', payload)
                        } else {
                            this.$emit('sendModel', this.form)
                        }
                    }else{
                        Object.keys(res.data.errors).map(item =>{
                            this.$toasted.error(res.data.errors[item][0], {
                                duration: 3000
                            })
                        })
                    }
                })

            }else{
                if (this.product) {
                    const payload = {
                        id: this.product.id,
                        data: this.form
                    }
                    this.$emit('changeProduct', payload)
                } else {
                    this.$emit('sendModel', this.form)
                }
            }
        },
        ...mapActions({
            getProductCategories: 'catalog/getProductCategories',
            getDepartments: 'catalog/getDepartments',
            getModels: 'catalog/getModels',
            getManufacturers: 'catalog/getManufacturers',
            storeProductImage: 'catalog/storeProductImage',
        }),
        ...mapMutations({
            changeProduct: 'catalog/changeProduct',
            changeShowProductPopup: 'catalog/changeShowProductPopup',
        }),
    },
    beforeDestroy() {

        this.changeProduct(null);
        console.log('clear products status true', this.product)
        this.toggleTextarea = false;
        this.productCategoryList = [];
        this.form = {
            title: '',
            id:0,
            full_title: '',
            category_id: '',
            model_id: '',
            department_id: '',
            material: '',
            manufacturer_id: '',
            images: [{
                image:'',
            }],
            sku: '',
            isbn: '',
            default_width:0,
            default_height:0,
            default_length:0,
            default_weight:0,
        };
        // hasImage: false,
        this.imageFile = null;
        this.imageSrc = null;
        this.imageUrl = '';
    },
}